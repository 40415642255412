<template>
  <div class="incomes-expenses">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'IncomesExpenses'
}
</script>

<style scoped>
.incomes-expenses {
  min-height: 76%;
  display: flex;
}
</style>
